<template>
    <v-row class="mx-0">
      <!-- Partes temporales -->
      <v-col cols="12">
        <div class="card">
          <div class="card-header card-header-icon card-header-rose">
            <div class="card-icon">
              <i class="material-icons">sticky_note_2</i>
            </div>
            <h3 class="card-title">Folio Temporal</h3>
          </div>
          <div class="card-body">
            <v-row>
              <v-col md="4" v-for="parte in temporales" v-bind:key="parte.id">
                <TicketBitacora v-bind:parte="parte" @clicked="onClickTicket" />
              </v-col>
              <v-col>
                <v-btn @click="sincronizar">Sincronizar Turno</v-btn>
              </v-col>
              <pre>{{ parte.es_descarga }}</pre>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <!-- bitacora de carga -->
          <v-expansion-panel v-if="mostrar.carga">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              BITACORA DE CARGA SMOLT
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="2">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                    v-model="parte.folio" dense readonly>
                  </v-text-field>
                </v-col>
              </v-row>
              <fieldset class="px-2 py-2 elevation-3" v-if="carga.es_nueva">
                <legend class="rounded-sm px-2 py-1">INFORMACION GENERAL</legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-pound" placeholder="N° Camiones" label="N° Camiones"
                      v-model="carga.camiones" dense type="number">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="carga.especie" :items="especies" item-text="nombre" item-value="id" label="Especie"
                      placeholder="Seleccione especie" return-object dense></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-swim" placeholder="Días de ayuno" label="Días de ayuno"
                      v-model="carga.dias_ayuno" type="text" dense>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
  
                  <!--PARAMETROS-->
                  <v-col cols="4" v-for="p in parameters" :key="`par_${p.id}`">
                    <v-select v-model="p.valor" prepend-icon="mdi-form-select" :items="p.opciones" :label="p.nombre"
                      item-text="nombre" item-value="id" dense></v-select>
                  </v-col>
                  <!--PARAMETROS-->
  
                  <!--<v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-swim"
                      placeholder="Natacion en bodegas"
                      label="Natacion en bodegas"
                      v-model="carga.natacion"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-air-conditioner"
                      placeholder="Boqueo en bodegas"
                      label="Boqueo en bodegas"
                      v-model="carga.boqueo"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-fish"
                      placeholder="Descamacion"
                      label="Descamacion"
                      v-model="carga.descamacion"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-medical-bag"
                      placeholder="Peces con heridas"
                      label="Peces con heridas"
                      v-model="carga.heridas"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-gate"
                      placeholder="Mortalidad en cerco"
                      label="Mortalidad en cerco"
                      v-model="carga.mortalidad_cerco"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>-->
  
                  <v-col cols="2">
                    <v-select prepend-icon="mdi-gate" v-model="carga.parte.empresa" :items="empresas"
                      item-text="razon_social" item-value="id" label="Empresa" return-object @change="loadCentros" dense>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select v-model="carga.parte.centro" :items="centros" item-text="nombre" item-value="id"
                      label="Centro de Descarga" return-object dense>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega1" :label="nbodega1"
                      v-model="carga.sello_babor" dense type="text">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega2" :label="nbodega2"
                      v-model="carga.sello_central" dense type="text">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="parte.wellboats_id != 7">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello estribor" label="N° sello estribor"
                      v-model="carga.sello_estribor" dense type="text">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 1" label="N° sello Yoma 1"
                      v-model="carga.sello_yoma1" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 2" label="N° sello Yoma 2"
                      v-model="carga.sello_yoma2" dense>
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
  
              <fieldset class="px-2 py-2 elevation-3" v-if="!carga.es_nueva">
                <legend class="rounded-sm px-2 py-1">INFORMACION GENERAL</legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-pound" placeholder="N° Camiones" label="N° Camiones"
                      v-model="carga.camiones" dense type="number">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="carga.especie" :items="especies" item-text="nombre" item-value="id" label="Especie"
                      placeholder="Seleccione especie" return-object dense></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-swim" placeholder="Días de ayuno" label="Días de ayuno"
                      v-model="carga.dias_ayuno" type="text" dense>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
  
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-swim" placeholder="Natacion en bodegas" label="Natacion en bodegas"
                      v-model="carga.natacion" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-air-conditioner" placeholder="Boqueo en bodegas"
                      label="Boqueo en bodegas" v-model="carga.boqueo" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-fish" placeholder="Descamacion" label="Descamacion"
                      v-model="carga.descamacion" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-medical-bag" placeholder="Peces con heridas" label="Peces con heridas"
                      v-model="carga.heridas" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-gate" placeholder="Mortalidad en cerco" label="Mortalidad en cerco"
                      v-model="carga.mortalidad_cerco" counter="20" dense>
                    </v-text-field>
                  </v-col>
  
                  <v-col cols="2">
                    <v-select prepend-icon="mdi-gate" v-model="carga.parte.empresa" :items="empresas"
                      item-text="razon_social" item-value="id" label="Empresa" return-object @change="loadCentros" dense>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select v-model="carga.parte.centro" :items="centros" item-text="nombre" item-value="id"
                      label="Centro de Descarga" return-object dense>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega1" :label="nbodega1"
                      v-model="carga.sello_babor" dense type="text">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega2" :label="nbodega2"
                      v-model="carga.sello_central" dense type="text">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="parte.wellboats_id != 7">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello estribor" label="N° sello estribor"
                      v-model="carga.sello_estribor" dense type="text">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 1" label="N° sello Yoma 1"
                      v-model="carga.sello_yoma1" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 2" label="N° sello Yoma 2"
                      v-model="carga.sello_yoma2" dense>
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
  
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">Bodegas</legend>
                <v-btn @click="dialog = true" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                <v-simple-table dense class="mx-2 my-2 elevation-3">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          N°
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Bodega
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Número de Peces
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Peso Promedio (Kg)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Biomasa Estimada (Kg)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Densidad de Carga (Kg/m3)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          N° Estanques
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Inicio
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Termino
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(detalle,index) in carga.bodegas" :key="detalle.numero">
                        <td class="text-center">{{ detalle.numero }}</td>
                        <td class="text-center" v-if="detalle.bodega.bodega == undefined">
                          {{ detalle.bodega.nombre }}
                        </td>
                        <td class="text-center" v-if="detalle.bodega.bodega != undefined">
                          {{ detalle.bodega.bodega.nombre }}
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.peces" type="number"
                            @input="recalcular(detalle)"></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.peso" type="number"
                            @input="recalcular(detalle)"></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.biomasa" type="number" readonly></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.densidad" type="number"
                            @input="recalcular(detalle)"></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-center" v-model="detalle.estanques"></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-datetime-picker label="Inicio (Fecha Hora)" v-model="detalle.hora_inicio"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td class="text-center">
                          <v-datetime-picker label="Término (Fecha Hora)" v-model="detalle.hora_termino"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td class="text-center">
                          <a @click="deleteBodega(detalle,index)"><span class="material-icons">delete</span></a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2">Total/Prom</td>
                        <td>
                          <v-text-field class="input-right" v-model="totalPeces" type="number" readonly></v-text-field>
                        </td>
                        <td>
                          <v-text-field class="input-right" v-model="totalPeso" type="number" readonly></v-text-field>
                        </td>
                        <td>
                          <v-text-field class="input-right" v-model="totalBiomasa" type="number" readonly></v-text-field>
                        </td>
                        <td>
                          <v-text-field class="input-right" v-model="totalDensidad" type="number" readonly></v-text-field>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </fieldset>
  
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Parámetros Registrados en bodegas Wellboat
                </legend>
                <v-btn @click="addLance" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Número
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Hora medición
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" colspan="4">
                          Parámetros Ambientales
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Acciones
                        </th>
                      </tr>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          Oxigeno (mg/L)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Saturacion (%)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          T (°C)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          PH
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="lance in carga.lances" :key="lance.numero">
                        <td style="border-right: 1px solid black;">
                          {{ lance.numero }}
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-datetime-picker label="Medicion (Fecha Hora)" v-model="lance.hora_medicion"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-text-field v-model="lance.oxigeno" suffix="mg/L" type="number"></v-text-field>
                        </td>
                        <td>
                          <v-text-field v-model="lance.saturacion" suffix="%" type="number"></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-text-field v-model="lance.temperatura" suffix="° C" type="number"></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-text-field v-model="lance.ph" type="number"></v-text-field>
                        </td>
                        <td>
                          <a @click="deleteLance"><span class="material-icons">delete</span></a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset>
  
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="500" label="Observaciones" v-model="carga.observaciones"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select v-if="capitanes.length > 0" v-model="carga.capitan" :items="capitanes" item-text="nombre"
                    item-value="id" label="Nombre Capitán" dense>
                  </v-select>
                  <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                    v-model="carga.capitan" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-if="tecnicos.length > 0" v-model="carga.tecnico_wellboat" :items="tecnicos" item-text="nombre"
                    item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="carga.tecnico_wellboat" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Encargado Smolt Cliente" placeholder="Encargado Smolt Cliente"
                    v-model="carga.encargado_smolt" dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn color="" @click="saveCarga(1)" :disabled="carga.temporal == 0">GUARDAR TEMPORAL</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="saveCarga(0)">GUARDAR CARGA</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- bitacora de traslado -->
          <v-expansion-panel v-if="mostrar.traslado">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              BITACORA DE TRASLADO SMOLT
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                    v-model="parte.folio" dense readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho 1era Carga" label="Guia Despacho 1"
                    v-model="traslado.guia_despacho" dense></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho 2da Carga" label="Guia Despacho 2"
                    v-model="traslado.guia_despacho_2" dense :disabled="!doble_carga"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaGuiaTraslado" label="Fecha guía de despacho" prepend-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense></v-text-field>
                    </template>
                    <v-date-picker v-model="traslado.fecha_despacho" @input="menu1 = false" locale="es-cl"
                      prev-icon="chevron_left" next-icon="chevron_right"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mx-0 mt-1">
                <v-col cols="3">
                  <v-text-field placeholder="Centro Carga" label="Centro Carga" v-model="traslado.centro_carga" dense readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field placeholder="Centro Descarga" label="Centro Descarga" v-model="traslado.centro_descarga"
                    dense readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field placeholder="Hora Inicio" label="Hora Inicio" v-model="traslado.hora_inicio_spanish" dense readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field placeholder="Hora Termino" label="Hora Termino" v-model="traslado.hora_termino_spanish" dense readonly>
                  </v-text-field>
                </v-col>
              </v-row>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Mediciones de parámetros
                </legend>
                <v-btn @click="addMedicionTraslado" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                <v-simple-table dense class="mx-0 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th rowspan="2" class="text-center blue-grey darken-4 white-text">
                          Número
                        </th>
                        <th rowspan="2" class="text-center blue-grey darken-4 white-text">
                          HORA
                        </th>
                        <th colspan="4" class="text-center blue-grey darken-4 white-text" v-for="bodega in bodegas"
                          :key="bodega.id">
                          {{ bodega.bodega.nombre }}
                        </th>
                        <th rowspan="2" class="text-center blue-grey darken-4 white-text">
                          Acciones
                        </th>
                      </tr>
                      <tr>
                        <template v-for="i in bodegas.length">
                          <th class="text-center blue-grey darken-4 white-text" :key="`${i}head_ox`">
                            O2 (mg/L)
                          </th>
                          <th class="text-center blue-grey darken-4 white-text" :key="`${i}head_ph`">
                            PH
                          </th>
                          <th class="text-center blue-grey darken-4 white-text" :key="`${i}head_uv`">
                            UV (ml/cm2)
                          </th>
                          <th class="text-center blue-grey darken-4 white-text" :key="`${i}head_temp`">
                            T (°C)
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(parametro,index) in traslado.parametros" :key="parametro.numero">
                        <td>{{ parametro.numero }}</td>
                        <td style="border-right: 1px solid black;">
                          <v-datetime-picker label="Medicion (Fecha Hora)" v-model="parametro.hora" dateFormat="dd-MM-yyyy"
                            timeFormat="HH:mm:ss" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <template v-for="(bodega,idx) in bodegas">
                          <td :key="`${bodega['bodega'].id}${idx}ox`">
                            <v-text-field v-model="parametro['b' + bodega['bodega'].id].oxigeno
                              " type="number" step="0.1" class="input-right"></v-text-field>
                          </td>
                          <td :key="`${bodega['bodega'].id}${idx}ph`">
                            <v-text-field v-model="parametro['b' + bodega['bodega'].id].ph" type="number"
                              class="input-right"></v-text-field>
                          </td>
                          <td :key="`${bodega['bodega'].id}${idx}uv`">
                            <v-text-field v-model="parametro['b' + bodega['bodega'].id].uv" type="number"
                              class="input-right"></v-text-field>
                          </td>
                          <td style="border-right: 1px solid black;" :key="`${bodega['bodega'].id}${idx}temp`">
                            <v-text-field v-model="parametro['b' + bodega['bodega'].id].temperatura
                              " type="number" class="input-right"></v-text-field>
                          </td>
                        </template>
                        <td>
                          <a @click="deleteParametroTraslado(parametro,index)"><span class="material-icons">delete</span></a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset>
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="500" label="Observaciones" v-model="traslado.observaciones"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select v-if="capitanes.length > 0" v-model="traslado.capitan" :items="capitanes" item-text="nombre"
                    item-value="id" label="Nombre Capitán" dense>
                  </v-select>
                  <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                    v-model="traslado.capitan" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-if="tecnicos.length > 0" v-model="traslado.tecnico_wellboat" :items="tecnicos"
                    item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="traslado.tecnico_wellboat" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Supervisor" placeholder="Supervisor" v-model="traslado.supervisor" dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn color="" @click="saveTraslado(1)" :disabled="traslado.temporal == 0">GUARDAR TEMPORAL</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="saveTraslado(0)">GUARDAR TRASLADO</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- bitacora de descarga -->
          <v-expansion-panel v-if="mostrar.descarga">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              BITACORA DE DESCARGA SMOLT
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                    v-model="parte.folio" dense readonly></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Fecha Folio" label="Fecha Folio"
                    v-model="descarga.fecha_despacho" dense readonly></v-text-field>
                </v-col>
                <v-col cols="4"></v-col>
              </v-row>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">INFORMACION GENERAL</legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Cliente" label="Cliente"
                      v-model="descarga.cliente" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Centro Destino / Descarga"
                      label="Centro Destino / Descarga" v-model="descarga.acopio" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Recalada" label="Hora Recalada"
                      v-model="descarga.recalada" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Atraco" label="Hora Atraco"
                      v-model="descarga.atraco" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Inicio" label="Hora Inicio"
                      v-model="descarga.hora_inicio" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Termino" label="Hora Termino"
                      v-model="descarga.hora_termino" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Especie" label="Especie"
                      v-model="descarga.especie" dense readonly></v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Parámetros medioambientales previo a descarga
                </legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field dense prepend-icon="mdi-seal" label="Saturacion" placeholder="Saturacion" suffix="%"
                      type="number" v-model="descarga.saturacion_centro"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense prepend-icon="mdi-temperature-celsius" label="Temperatura" placeholder="Temperatura"
                      type="number" suffix="° C" v-model="descarga.temperatura_centro"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense prepend-icon="mdi-seal" label="Oxigeno" placeholder="Oxigeno" suffix="mg/L"
                      type="number" v-model="descarga.oxigeno_centro"></v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Detalle distribución de descarga
                </legend>
                <v-btn @click="addDescarga" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          N°
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Bodega
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Inicio
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Término
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          N° Jaula
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          N° Smolt
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(descarga,index) in descarga.descargas_jaulas" :key="descarga.numero">
                        <td>{{ descarga.numero }}</td>
                        <td>
                          <v-select v-model="descarga.bodegas_id" :items="bodegas" item-text="bodega.nombre"
                            item-value="bodega.id" label="Destino">
                          </v-select>
                        </td>
                        <td>
                          <v-datetime-picker label="Inicio (Fecha Hora)" v-model="descarga.hora_inicio"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-datetime-picker label="Termino (Fecha Hora)" v-model="descarga.hora_termino"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-text-field v-model="descarga.jaula"></v-text-field>
                        </td>
                        <td>
                          <v-text-field v-model="descarga.smolts"></v-text-field>
                        </td>
                        <td>
                          <a @click="deleteDescargaJaula(descarga,index)"><span class="material-icons">delete</span></a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset>
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="500" label="Observaciones" v-model="descarga.observaciones"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select v-if="capitanes.length > 0" v-model="descarga.capitan" :items="capitanes" item-text="nombre"
                    item-value="id" label="Nombre Capitán" dense>
                  </v-select>
                  <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                    v-model="descarga.capitan" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-if="tecnicos.length > 0" v-model="descarga.tecnico" :items="tecnicos"
                    item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="descarga.tecnico" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field dense prepend-icon="mdi-seal" placeholder="Jefe Centro" label="Jefe Centro"
                    v-model="descarga.encargado_acopio"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn color="" @click="saveDescarga(1)" :disabled="descarga.temporal == 0">GUARDAR TEMPORAL</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="saveDescarga(0)">GUARDAR DESCARGA</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- finalizar servicios wellboat -->
        <v-row v-if="descarga.temporal == 0">
          <v-col cols="6">
            <v-file-input v-model="files" label="Adjuntar documentación" show-size counter multiple
              prepend-icon="attach_file" chips accept=".pdf"></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-btn color="green" @click="saveFinal" :disabled="finalizar == false">FINALIZAR SERVICIO WELLBOAT</v-btn>
          </v-col>
        </v-row>
      </v-col>
  
      <v-snackbar v-model="snackbar" :timeout="60000" :color="color">
        {{ validation_message }}
        <ul v-if="errors.length > 0">
            <li v-for="error in errors" :key="error">{{error}}</li>
        </ul>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
  
      <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Agregar a bodega</v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select v-model="bodega.bodega" :items="bodegas" item-text="bodega.nombre" item-value="bodega.id"
                  label="Bodega" placeholder="Seleccione bodega" return-object></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Peces" placeholder="Peces" @change="calcularBiomasa"
                  v-model="bodega.peces"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Peso" placeholder="Peso" @change="calcularBiomasa"
                  v-model="bodega.peso"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Biomasa" placeholder="Biomasa" v-model="bodega.biomasa"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Densidad" placeholder="Densidad" v-model="bodega.densidad"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="N° Estanques Camión" placeholder="N° Estanques Camión"
                  v-model="bodega.estanques"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-datetime-picker label="Inicio (Fecha Hora)" v-model="bodega.hora_inicio" dateFormat="dd-MM-yyyy"
                  :datePickerProps="{
                    locale: 'es-es',
                    prevIcon: 'chevron_left',
                    nextIcon: 'chevron_right',
                  }" :timePickerProps="{ format: '24hr' }">
                  <template slot="dateIcon">
                    <v-icon>calendar_today</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>access_time</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="4">
                <v-datetime-picker label="Termino (Fecha Hora)" v-model="bodega.hora_termino" dateFormat="dd-MM-yyyy"
                  :datePickerProps="{
                    locale: 'es-es',
                    prevIcon: 'chevron_left',
                    nextIcon: 'chevron_right',
                  }" :timePickerProps="{ format: '24hr' }">
                  <template slot="dateIcon">
                    <v-icon>calendar_today</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>access_time</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="addCargaBodega" color="green">Agregar</v-btn>
            <v-btn @click="dialog = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <style scoped>
  legend {
    background-color: #263238;
    color: #fff;
    font-size: 14pt;
  }
  
  .card {
    margin-top: 0;
  }
  
  .card .card-header-rose .card-icon {
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(32, 30, 31, 0.822);
  }
  
  fieldset {
    margin-bottom: 10px;
  }
  
  .input-right>>>input {
    text-align: right;
  }
  
  .input-center>>>input {
    text-align: center;
  }
  
  .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #fff;
  }
  
  .theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
    color: #fff;
  }
  </style>
  <script>
  import { mapState, mapMutations } from "vuex";
  import TicketBitacora from "@/components/TicketBitacora.vue";
  
  export default {
    data: () => ({
      showTraslado: false,
      showDescarga: false,
      centros: [],
      capitanes: [],
      tecnicos: [],
      files: [],
      menuInicio: false,
      menuMedicion: false,
      menuTermino: false,
      dialog: false,
      time: null,
      color: "green",
      snackbar: false,
      errors: [],
      validation_message: "",
      success: null,
      search: null,
      folioSearch: "",
      empresas: [],
      partes: [],
      cargaIndex: -1,
      isLoading: false,
      wellboat: "",
      folio: "",
      parte: {},
      folios: [],
      panel: 0,
      temporales: [],
      bodegas: [],
      total: {},
      showPicker: null,
      menu1: false,
      especies: [],
      bodega: {
        bodega: {},
        peces: 0,
        peso: 0,
        biomasa: 0,
        lances: "",
        jaula: "",
      },
      carga: {
        id: 0,
        parte: {},
        centro: {},
        especie: {},
        guia_despacho: "",
        fecha_despacho: "",
        dias_ayuno: "0",
        natacion: "",
        boqueo: "",
        descamacion: "",
        esquinados: "",
        lobos: "",
        heridas: "",
        mortalidad_cerco: "",
        sello_babor: "",
        sello_central: "",
        sello_estribor: "",
        temperatura_centro: "",
        oxigeno_centro: "",
        saturacion_centro: "",
        observaciones: "",
        jefe_centro: "",
        tecnico_wellboat: "",
        bodegas: [],
        lances: [],
      },
      traslado: {
        parte: {},
        observaciones: "",
        tecnico_wellboat: "",
        supervisor: "",
        capitan: "",
        parametros: [{ numero: 1, hora: "" }],
        fecha_despacho: "",
        guia_despacho: "",
      },
      descarga: {
        parte: {},
        muestreo_repla: "",
        entidad_muestradora: "",
        mortalidad: "",
        saturacion_centro: "",
        temperatura_centro: "",
        oxigeno_centro: "",
        observaciones: "",
        capitan: {},
        tecnico: {},
        jaulas: [],
        tecnico_wellboat: "",
        fecha: "",
      },
      nbodega1: "N° sello babor",
      nbodega2: "N° sello central",
      nbodega3: "N° sello estribor",
      parameters: [],
      mostrar: {
        carga: false,
        traslado: false,
        descarga: false,
        descarga_planta: false,
      },
      doble_carga:false,
    }),
    async mounted() {
      this.empresas = this.$store.getters["empresa/getActive"];
      this.bodegas = this.$store.state.user.wellboat.bodegas.map((b) => {
        return {
          bodega: b.bodega,
          m3: b.m3,
        };
      });
      this.especies = this.$store.getters["especie/getActive"];
      await this.loadParameters();
      await this.loadPartesTemporales();
    },
    methods: {
      async loadParameters() {
        let url = `${this.base_url}tipos_bitacoras/${2}/cargar_parametros`
        await this.axios.get(url, this.headers).then(r => {
          this.parameters = r.data
        })
      },
      onClickTicket(parte) {
        this.parte = parte;
        this.cargarBitacoras();
        //this.carga.parte = parte;
        //this.loadTecnicos();
        //this.loadCargaParte();
        //this.loadTrasladoParte();
        //this.loadDescargaParte();
      },
      async sincronizar() {
        let parte = this.temporales[0];
        if (parte != undefined) {
          let inicio = parte.zarpe.substring(0, 10);
          let termino = parte.recalada.substring(0, 10);
          //console.log(inicio, termino)
          let url = `${this.base_url}turnos/obtener`;
          await this.axios
            .post(url, { fecha: inicio }, this.headers)
            .then((response) => {
              console.log(response);
              if (inicio != termino) {
                this.axios
                  .post(url, { fecha: termino }, this.headers)
                  .then((response) => {
                    console.log(response);
                    this.onClickTicket(parte);
                  });
              } else {
                this.onClickTicket(parte);
              }
            });
        }
      },
      loadCentros() {
        this.centros = this.carga.parte.empresa.centros;
      },
      async loadTecnicos() {
        let url = `${this.base_url}cargas/tecnicos/${this.user.wellboat.id}/${this.parte.folio}`;
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.tecnicos = response.data;
            console.log("load tecnicos", this.tecnicos);
            if (this.tecnicos.length > 0) {
              this.carga.tecnico_wellboat = this.tecnicos[0];
              this.traslado.tecnico_wellboat = this.tecnicos[0];
              this.descarga.tecnico_wellboat = this.tecnicos[0];
            }
            this.loadCapitanes();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async loadCapitanes() {
        let url = `${this.base_url}cargas/capitanes/${this.user.wellboat.id}/${this.parte.folio}`;
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.capitanes = response.data;
            if (!this.carga.capitan) this.carga.capitan = this.capitanes[0];
            if (!this.traslado.capitan) this.traslado.capitan = this.capitanes[0];
            if (!this.descarga.capitan) this.descarga.capitan = this.capitanes[0];
            if (this.tecnicos.length == 0) {
              this.tecnicos = this.capitanes;
              if (this.tecnicos.length > 0) {
                //console.log('load tecnicos', this.tecnicos);
                if (!this.carga.tecnico_wellboat)
                  this.carga.tecnico_wellboat = this.tecnicos[0];
                if (!this.traslado.tecnico_wellboat)
                  this.traslado.tecnico_wellboat = this.tecnicos[0];
                if (!this.descarga.tecnico_wellboat)
                  this.descarga.tecnico_wellboat = this.tecnicos[0];
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async loadBodegas() {
        let url = `${this.base_url}wellboat_bodegas/bywellboat/${this.user.wellboat.id}`;
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.bodegas = response.data;
            this.carga.bodega = this.bodegas[0];
            this.bodega.bodega = this.bodegas[0];
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async loadCargaParte() {
        let url = `${this.base_url}cargas/byparte/${this.carga.parte.id}`;
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.carga = response.data;
            //si la carga no tiene especie, se setea por defecto la primera especie del listado
            if (this.carga.especie == undefined || this.carga.especie == null) {
              this.carga.especie = this.especies[0];
            }
            let n = 1;
            this.carga.bodegas.forEach((b) => {
              b.hora_inicio = this.moment(b.hora_inicio).toDate();
              b.hora_termino = this.moment(b.hora_termino).toDate();
              this.recalcular(b);
              b.numero = n++;
            });
            this.carga.lances.forEach((l) => {
              l.hora_inicio = this.moment(l.hora_inicio).toDate();
              l.hora_termino = this.moment(l.hora_termino).toDate();
              l.hora_medicion = this.moment(l.hora_medicion).toDate();
              this.cargaEfectiva(l);
            });
            //console.log('carga.parte.id', this.carga.parte.id)
            //console.log('parte.id', this.parte.id)
            //if(this.carga.parte.id != this.parte.id)
            if (((this.carga.parte.duplicar == 1 && this.carga.parte.temporal == 0) || this.carga.parte.duplicar == 0) && this.carga.parte.tipopartes_id != 5 && this.carga.temporal == 0 && this.carga.parte.temporal == 0
            ) {
              this.panel = 1;
              this.showTraslado = true;
              this.showDescarga = true;
              this.loadTrasladoParte();
              this.loadDescargaParte();
            }
            this.loadCentros();
            this.setearParametros();
          })
          .catch((error) => {
            this.showTraslado = true;
            this.showDescarga = true;
            this.loadTrasladoParte();
            this.loadDescargaParte();
            console.log(error);
          });
      },
      setearParametros() {
        this.parameters.forEach((p, idx) => {
          let par = this.carga.parametros.find(item => item.parametros_id == p.id)
          if (par) {
            this.$set(this.parameters[idx], 'valor', par.parametros_opciones_id)
          }
  
        });
      },
      async loadDescargaParte() {
        let url = `${this.base_url}descargas/byparte/${this.parte.id}`;
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.descarga = response.data;
            this.descarga.jaulas.forEach((l) => {
              l.hora_inicio = this.moment(l.hora_inicio).toDate();
              l.hora_termino = this.moment(l.hora_termino).toDate();
            });
            if (
              this.tecnicos.length == 0 &&
              this.descarga.tecnico_wellboat == ""
            ) {
              this.descarga.tecnico_wellboat = this.descarga.capitan.name;
            }
            this.descarga.recalada = this.moment(this.descarga.recalada).format(
              "DD-MM-YYYY HH:mm"
            );
            this.descarga.atraco = this.moment(this.descarga.atraco).format(
              "DD-MM-YYYY HH:mm"
            );
  
            this.descarga.fecha = this.moment(this.descarga.fecha).format(
              "DD-MM-YYYY"
            );
  
            if (
              this.capitanes.length == 0 &&
              typeof this.descarga.capitan == "object"
            ) {
              this.descarga.capitan = this.descarga.capitan.nombre;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async loadTrasladoParte() {
        let url = `${this.base_url}traslados/byparte/${this.parte.id}/${this.user.wellboat.id}`;
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.traslado = response.data;
            this.traslado.parametros.forEach((p) => {
              p.hora = this.moment(p.hora).toDate();
            });
            if (this.traslado.temporal == 0) {
              this.panel = 2;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async loadPartesTemporales() {
        let url = `${this.base_url}partes/portipo/temporal/smolt`;
        if (this.user.wellboat != undefined && this.user.wellboat.id > 0) {
          url = `${this.base_url}partes/temporales/wellboat/smolt/${this.user.wellboat.id}`;
        }
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.temporales = response.data;
            if (
              this.temporales.length > 0 &&
              this.user.wellboat != undefined &&
              this.user.wellboat.id > 0
            ) {
              let parte = this.temporales[0];
              if (parte) {
                this.parte = parte;
                this.loadTecnicos();
                this.cargarBitacoras();
                //this.carga.parte = parte;
                //this.loadCargaParte();
                //this.loadTrasladoParte();
                //this.loadDescargaParte();
                if (this.parte.wellboats_id == 7) {
                  // don mauro
                  this.nbodega1 = "N° sello Proa";
                  this.nbodega2 = "N° sello Popa";
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

    async cargarBitacoras() {
        this.carga = {
            parte:{},
            lances: [],
            bodegas: [],
        };
        this.traslado = {};
        this.descarga = {};
        //this.descarga_planta = {};

        let url = `${this.base_url}partes/${this.parte.id}/bitacoras`;
        await this.axios.get(url, this.headers_json).then((r) => {
            console.log("data", r.data)
            //indica si es un parte con doble carga
            this.doble_carga = r.data.doble_carga;
            //bitacora de carga
            this.carga = r.data.carga;
            /*if (this.parte.termino_cam) {
            this.termino_cam = this.moment(this.parte.termino_cam).toDate();
            }*/
            this.formatearFechaParaDatepicker("carga");
            this.setearParametros()
            this.loadCentros()
            //bitacora de carga

            //bitacora de traslado
            if (r.data.traslado != null) {
                this.traslado = r.data.traslado;
                this.traslado.hora_inicio_spanish = this.$options.filters.format_date_spanish(this.traslado.hora_inicio);
                this.traslado.hora_termino_spanish = this.$options.filters.format_date_spanish(this.traslado.hora_termino);
                this.formatearFechaParaDatepicker("traslado");
            }
            //bitacora de traslado

            //bitacora de descarga
            if (r.data.descarga != null) {
                this.descarga = r.data.descarga;
                this.descarga.recalada = this.$options.filters.format_date_spanish(this.descarga.recalada);
                this.descarga.atraco = this.$options.filters.format_date_spanish(this.descarga.atraco);
                this.descarga.hora_inicio = this.$options.filters.format_date_spanish(this.descarga.hora_inicio);
                this.descarga.hora_termino = this.$options.filters.format_date_spanish(this.descarga.hora_termino);
                this.formatearFechaParaDatepicker("descarga");
            }
            //bitacora de descarga

            this.mostrar = r.data.mostrar;

            if(this.parte.es_descarga == 1){
                this.panel = this.traslado.temporal == 1? 1:2
            }
        });
    },

    formatearFechaParaDatepicker(bitacora = "") {

      if (bitacora == "carga") {
        //reformateando fechas carga (para mostrar en datepicker)
        this.carga.lances.forEach((l) => {
          l.key = this.generarKey(5);
          l.hora_medicion = this.$options.filters.format_string_a_fecha(
            l.hora_medicion
          );
        });

        this.carga.bodegas.forEach((b,idx) => {
          b.key = this.generarKey(5);
          b.hora_inicio = this.$options.filters.format_string_a_fecha(
            b.hora_inicio
          );
          b.hora_termino = this.$options.filters.format_string_a_fecha(
            b.hora_termino
          );
          b.numero = idx + 1;
        });
      }

      if(bitacora == "traslado"){
        this.traslado.parametros.forEach(p => {
            p.hora = this.$options.filters.format_string_a_fecha(p.hora)
        })
      }

      if(bitacora == "descarga"){
        this.descarga.descargas_jaulas.forEach(j => {
            j.hora_inicio = this.$options.filters.format_string_a_fecha(j.hora_inicio)
            j.hora_termino = this.$options.filters.format_string_a_fecha(j.hora_termino)
        })
      }
    },

    generarKey(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
  
      buscar_valor_parametro(id) {
        let valor = this.parameters.find(p => p.id == id).valor
        return valor
      },
      async saveCarga(temporal = 1) {
        // validation
        this.errors = [];
        let lances = [];
        let bodegas = [];
        if (!this.parte.folio) {
          this.errors.push("Debe elegir un parte de viaje");
        }
        if (isNaN(this.carga.camiones) && this.carga.camiones <= 0) {
          this.errors.push("Camiones debe ser mayor que cero");
        }
        if (!temporal) {
          if (!this.carga.capitan) {
            this.errors.push("Debe indicar capitán wellboat");
          }
          if (!this.carga.especie) {
            this.errors.push("Debe indicar especie");
          }
          if (this.carga.dias_ayuno == "") {
            this.errors.push("Debe indicar dias de ayuno");
          }
  
          if (this.carga.es_nueva) {
            //usando nuevo formato de informacion general
            if (!this.buscar_valor_parametro(1)) {
              this.errors.push("Debe indicar natación");
            }
            if (!this.buscar_valor_parametro(2)) {
              this.errors.push("Debe indicar boqueo en bodegas");
            }
            if (!this.buscar_valor_parametro(3)) {
              this.errors.push("Debe indicar descamación");
            }
            if (!this.buscar_valor_parametro(6)) {
              this.errors.push("Debe indicar heridas");
            }
            if (!this.buscar_valor_parametro(7)) {
              this.errors.push("Debe indicar mortalidad en cerco");
            }
          }
  
          if (!this.carga.es_nueva) {
            if (
              this.carga.natacion == "" ||
              this.carga.natacion == undefined ||
              this.carga.natacion == null
            ) {
              this.errors.push("Debe indicar natación");
            } else {
              if (
                this.carga.natacion != undefined &&
                this.carga.natacion.length > 20
              ) {
                this.errors.push("Natación no debe superar 20 caracteres");
              }
            }
  
            if (
              this.carga.boqueo == "" ||
              this.carga.boqueo == undefined ||
              this.carga.boqueo == null
            ) {
              this.errors.push("Debe indicar boqueo en bodegas");
            } else {
              if (this.carga.boqueo != undefined && this.carga.boqueo.length > 20) {
                this.errors.push("Boqueo no debe superar 20 caracteres");
              }
            }
  
            if (
              this.carga.descamacion == "" ||
              this.carga.descamacion == undefined ||
              this.carga.descamacion == null
            ) {
              this.errors.push("Debe indicar descamación");
            } else {
              if (
                this.carga.descamacion != undefined &&
                this.carga.descamacion.length > 20
              ) {
                this.errors.push("Descamacion no debe superar 20 caracteres");
              }
            }
  
            if (
              this.carga.heridas == "" ||
              this.carga.heridas == undefined ||
              this.carga.heridas == null
            ) {
              this.errors.push("Debe indicar heridas");
            } else {
              if (
                this.carga.heridas != undefined &&
                this.carga.heridas.length > 20
              ) {
                this.errors.push("Heridas no debe superar 20 caracteres");
              }
            }
  
            if (
              this.carga.mortalidad_cerco == "" ||
              this.carga.mortalidad_cerco == undefined ||
              this.carga.mortalidad_cerco == null
            ) {
              this.errors.push("Debe indicar mortalidad en cerco");
            } else {
              if (
                this.carga.mortalidad_cerco != undefined &&
                this.carga.mortalidad_cerco.length > 20
              ) {
                this.errors.push("Mortalidad cerco no debe superar 20 caracteres");
              }
            }
          }
  
  
  
          if (this.carga.sello_babor == "" && this.carga.sello_babor != 0) {
            if (this.parte.wellboats_id == 7) {
              this.errors.push("Debe indicar sello proa");
            } else {
              this.errors.push("Debe indicar sello babor");
            }
          }
          if (this.carga.sello_central == "" && this.carga.sello_central != 0) {
            if (this.parte.wellboats_id == 7) {
              this.errors.push("Debe indicar sello popa");
            } else {
              this.errors.push("Debe indicar sello central");
            }
          }
          if (this.carga.sello_estribor == "" && this.carga.sello_estribor != 0) {
            if (this.parte.wellboats_id != 7) {
              this.errors.push("Debe indicar sello estribor");
            }
          }
          if (this.carga.lances.length == 0) {
            this.errors.push("Debe ingresar al menos un lance");
          }
          if (this.carga.bodegas.length == 0) {
            this.errors.push("Debe ingresar al menos una bodega");
          }
          if (
            this.carga.tecnico_wellboat == "" ||
            this.carga.tecnico_wellboat == null ||
            this.carga.tecnico_wellboat == undefined
          ) {
            this.errors.push("Debe indicar tecnico wellboat");
          }
          if (!this.carga.parte.centro) {
            this.errors.push("Debe indicar centro");
          }
        }
        if (this.errors.length > 0) {
          //this.validation_message = this.errors.join(".");
          this.validation_message = "Revise los siguientes problemas de validación:";
          this.showSnackBar(true);
          return;
        }
        
        if (this.carga.lances.length > 0) {
          // se crea array de lances para guardar
          this.carga.lances.forEach((l) => {
            let lance = {
              cargas_id: l.cargas_id,
              numero: l.numero,
              hora_inicio: this.moment(l.hora_medicion).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              oxigeno: l.oxigeno,
              saturacion: l.saturacion,
              temperatura: l.temperatura,
              peces: l.peces,
              hora_termino: this.moment(l.hora_medicion).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              hora_medicion: this.moment(l.hora_medicion).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              ph: l.ph,
            };
            lances.push(lance);
          });
        }
        if (this.carga.bodegas.length > 0) {
          // se actualiza hora de inicio y termino del parte
          let ul = this.carga.bodegas.length;
          let l1 = this.carga.bodegas[0];
          let l2 = this.carga.bodegas[ul - 1];
          let t = this.temporales[0];
          if (this.carga.lances.length > 0) {
            t.inicio_faena = l1.hora_inicio;
            t.termino_faena = l2.hora_termino;
          }
          this.carga.bodegas.forEach((b) => {
            let bodega = {
              cargas_id: b.cargas_id,
              bodegas_id:
                b.bodega.bodega != undefined ? b.bodega.bodega.id : b.bodega.id,
              peces: b.peces,
              peso: b.peso,
              biomasa: b.biomasa,
              densidad: b.densidad,
              lances: 0,
              jaula: "",
              estanques: b.estanques,
              hora_inicio: this.moment(b.hora_inicio).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              hora_termino: this.moment(b.hora_termino).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            };
            bodegas.push(bodega);
          });
        }
  
        let tecnico_wellboat = this.carga.tecnico_wellboat;
        if(tecnico_wellboat){
            if (typeof tecnico_wellboat === "object") {
                tecnico_wellboat = this.carga.tecnico_wellboat.nombre;
            }
        }
        
        let capitan = "";
        if (typeof this.carga.capitan == "object") {
          capitan = this.carga.capitan.nombre;
        } else {
          capitan = this.carga.capitan;
        }
        let url = `${this.base_url}cargas`;
        let cargaData = {
          main: {
            partes_id: this.carga.parte.id,
            centros_id: this.carga.parte.centro?.id,
            wellboats_id: this.user.wellboat.id,
            guia_despacho: this.carga.guia_despacho,
            fecha_despacho: this.carga.parte.recalada,
            especies_id: this.carga.especie?.id,
            dias_ayuno: this.carga.dias_ayuno,
            natacion: this.carga.natacion,
            boqueo: this.carga.boqueo,
            descamacion: this.carga.descamacion,
            esquinados: this.carga.esquinados,
            lobos: this.carga.lobos,
            heridas: this.carga.heridas,
            mortalidad_cerco: this.carga.mortalidad_cerco,
            sello_babor: this.carga.sello_babor,
            sello_central: this.carga.sello_central,
            sello_estribor: this.carga.sello_estribor,
            temperatura_centro: this.carga.temperatura_centro,
            oxigeno_centro: this.carga.oxigeno_centro,
            saturacion_centro: this.carga.saturacion_centro,
            observaciones: this.carga.observaciones,
            jefe_centro: "",
            tecnico_wellboat: tecnico_wellboat,
            temporal: temporal,
            smolt: 1,
            camiones: this.carga.camiones,
            sello_bodegas: "",
            sello_yoma1: this.carga.sello_yoma1,
            sello_yoma2: this.carga.sello_yoma2,
            encargado_smolt: this.carga.encargado_smolt,
            capitan: capitan,
            parametros: this.parameters
          },
          bodegas: bodegas,
          parametros: lances,
        };
        let response = null;
        let msg = null;
        if (temporal == 0) {
          msg =
            "Ahora debe ir a Parte de viaje, ingresar Fecha/Hora CAM y Guardar Parte de Viaje";
        }
        if (this.carga.id) {
          url = url + "/" + this.carga.id;
          try {
            response = await this.axios.put(url, cargaData, this.headers_json);
            //this.onClickTicket(this.carga.parte);
            this.onClickTicket(this.parte);
            this.showSnackBar(false, msg);
          } catch (error) {
            response = error.response;
            this.errors = response.data.data;
            /*let msg = response.data.data;
            this.validation_message =
              response.status + ": " + response.data.message + ". " + msg;*/
            this.validation_message = "Revise los siguientes problemas de validación:";
            this.showSnackBar(true);
          }
        } else {
          try {
            response = await this.axios.post(url, cargaData, this.headers_json);
            //this.onClickTicket(this.carga.parte);
            this.onClickTicket(this.parte);
            this.showSnackBar(false, msg);
            if (temporal == 0) this.panel = 1;
          } catch (error) {
            response = error.response;
            this.errors = response.data.data;
            /*let msg = response.data.data;
            this.validation_message =
              response.status + ": " + response.data.message + ". " + msg;*/
            this.validation_message = "Revise los siguientes problemas de validación:";
            this.showSnackBar(true);
          }
        }
        if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
          window.open(this.storage_url + response.data.url_pdf);
        }
      },
      async saveTraslado(temporal = 1) {
        // validation
        this.errors = [];
        if (!this.parte.folio) {
          this.errors.push("Debe elegir un parte de viaje");
        }
        if (this.traslado.parametros.length == 0) {
          this.errors.push("Debe ingresar al menos una medición");
        } else {
          let traslado = this.traslado.parametros[0];
          if (!traslado.hora) {
            this.errors.push("Debe ingresar hora de la medición");
          }
        }
        if (!temporal) {
          if (!this.traslado.tecnico_wellboat) {
            this.errors.push("Debe indicar técnico wellboat");
          }
          if (!this.traslado.supervisor) {
            this.errors.push("Debe indicar supervisor de peces");
          }
          if (!this.traslado.capitan) {
            this.errors.push("Debe indicar capitán wellboat");
          }
          if (!this.traslado.centro_descarga) {
            this.errors.push("Falta centro de descarga");
          }
          if (!this.traslado.hora_inicio) {
            this.errors.push("Falta hora de inicio");
          }
          if (!this.traslado.hora_termino) {
            this.errors.push("Falta hora de termino");
          }
          if (!this.traslado.fecha_despacho) {
            this.errors.push("Falta fecha de despacho");
          }
          if (!this.traslado.guia_despacho) {
            this.errors.push("Debe indicar guía de despacho 1era Carga");
          }
          if (!this.traslado.guia_despacho_2 && this.doble_carga) {
            this.errors.push("Debe indicar guía de despacho 2da Carga");
          }
        }
        let parametros = [];
        this.traslado.parametros.forEach((p) => {
          //p.hora = this.moment(p.hora).format("YYYY-MM-DD HH:mm:ss");
          /*
            if(p.hora < this.traslado.hora_inicio){
              this.errors.push("Hora de medición debe ser mayor que hora de inicio");
            }
            */
           let parametro = Object.assign({}, p)
           parametro.hora = this.moment(parametro.hora).format("YYYY-MM-DD HH:mm:ss")
           parametros.push(parametro)
          //parametros.push(p);
        });
        if (this.errors.length > 0) {
          //this.validation_message = this.errors.join(". ");
          this.validation_message = "Revise los siguientes problemas de validación:";
          this.showSnackBar(true);
          return;
        }
        let tecnico_wellboat = this.traslado.tecnico_wellboat;
        if(tecnico_wellboat){
            if (typeof this.traslado.tecnico_wellboat === "object") {
            tecnico_wellboat = this.traslado.tecnico_wellboat.nombre;
            }
        }
        let capitan = "";
        if(this.traslado.capitan){
            if (typeof this.traslado.capitan == "object") {
                if (this.traslado.capitan.nombre != undefined) {
                    capitan = this.traslado.capitan.nombre;
                }
            } else {
                capitan = this.traslado.capitan;
            }
        }
  
        let url = `${this.base_url}traslados`;
        let trasladoData = {
          main: {
            partes_id: this.traslado.partes_id,
            wellboats_id: this.traslado.wellboats_id,
            observaciones: this.traslado.observaciones,
            tecnico_wellboat: tecnico_wellboat,
            supervisor: this.traslado.supervisor,
            capitan: capitan,
            centro_carga: this.traslado.centro_carga,
            centro_descarga: this.traslado.centro_descarga,
            //hora_inicio: this.reformatDateTime(this.traslado.hora_inicio),
            //hora_termino: this.reformatDateTime(this.traslado.hora_termino),
            hora_inicio: this.traslado.hora_inicio,
            hora_termino: this.traslado.hora_termino,
            temporal: temporal,
            fecha_despacho: this.traslado.fecha_despacho,
            guia_despacho: this.traslado.guia_despacho,
            guia_despacho_2: this.traslado.guia_despacho_2,
          },
          parametros: parametros,
        };
        let response = null;
        if (this.traslado.id > 0) {
          url = url + "/" + this.traslado.id;
          try {
            response = await this.axios.put(url, trasladoData, this.headers_json);
            //this.onClickTicket(this.traslado.parte);
            this.onClickTicket(this.parte);
            this.showSnackBar(false);
          } catch (error) {
            response = error.response;
            let msg = response.data.data;
            this.validation_message =
              response.status + ": " + response.data.message + ". " + msg;
            this.showSnackBar(true);
          }
        } else {
          try {
            response = await this.axios.post(
              url,
              trasladoData,
              this.headers_json
            );
            //this.onClickTicket(this.traslado.parte);
            this.onClickTicket(this.parte);
            this.showSnackBar(false);
          } catch (error) {
            response = error.response;
            if (response == undefined) {
              this.showSnackBar(false);
              return;
            }
            let msg = response.data.data;
            this.validation_message =
              response.status + ": " + response.data.message + ". " + msg;
            this.showSnackBar(true);
          }
        }
        if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
          window.open(this.storage_url + response.data.url_pdf);
        }
      },
      async saveDescarga(temporal = 1) {
        // validation
        this.errors = [];
        let hora_inicio = null;
        let hora_termino = null;
        if (!this.parte.folio) {
          this.errors.push("Debe elegir un parte de viaje");
        }
        if (this.descarga.descargas_jaulas.length == 0) {
            this.errors.push("Debe ingresar al menos una descarga");
          } else {
            hora_inicio = this.descarga.descargas_jaulas.reduce(function (p, v) {
              return p.hora_inicio < v.hora_inicio
                ? p.hora_inicio
                : v.hora_inicio;
            });
            //this.descarga.hora_inicio = hora_inicio;
            hora_termino = this.descarga.descargas_jaulas.reduce(function (p, v) {
              return p.hora_termino < v.hora_termino
                ? p.hora_termino
                : v.hora_termino;
            });
            //this.descarga.hora_termino = hora_termino;
        }

        if (!temporal) {
          if (isNaN(this.descarga.saturacion_centro)) {
            this.errors.push("Debe ingresar saturacion de oxigeno");
          }
          if (isNaN(this.descarga.temperatura_centro)) {
            this.errors.push("Debe ingresar temperatura");
          }
          if (isNaN(this.descarga.oxigeno_centro)) {
            this.errors.push("Debe ingresar oxigeno");
          }
          if (
            this.descarga.encargado_acopio == "" ||
            this.descarga.encargado_acopio == null ||
            this.descarga.encargado_acopio == undefined
          ) {
            this.errors.push("Debe ingresar encargado acopio");
          }
          if (
            this.descarga.tecnico == "" ||
            this.descarga.tecnico == null ||
            this.descarga.tecnico == undefined
          ) {
            this.errors.push("Debe ingresar tecnico wellboat");
          }
          if (
            this.descarga.capitan == "" ||
            this.descarga.capitan == null ||
            this.descarga.capitan == undefined
          ) {
            this.errors.push("Debe ingresar capitan");
          }
          if (this.descarga.descargas_jaulas.length == 0) {
            this.errors.push("Debe ingresar al menos una descarga");
          } else {
            hora_inicio = this.descarga.descargas_jaulas.reduce(function (p, v) {
              return p.hora_inicio < v.hora_inicio
                ? p.hora_inicio
                : v.hora_inicio;
            });
            //this.descarga.hora_inicio = hora_inicio;
            hora_termino = this.descarga.descargas_jaulas.reduce(function (p, v) {
              return p.hora_termino < v.hora_termino
                ? p.hora_termino
                : v.hora_termino;
            });
            //this.descarga.hora_termino = hora_termino;
          }
          if (!this.descarga.recalada) {
            this.errors.push("Falta hora de recalada");
          }
          if (!this.descarga.atraco) {
            this.errors.push("Falta hora de atraco");
          }
          if (!hora_inicio) {
            this.errors.push("Falta hora de inicio");
          }
          console.log('hora_inicio',hora_inicio)
          console.log('hora_termino',hora_termino)
          if (!hora_termino) {
            this.errors.push("Falta hora de termino");
          }
          if (
            this.descarga.muestreo_repla != undefined &&
            this.descarga.muestreo_repla.length > 30
          ) {
            this.errors.push("Muestreo Repla no debe superar 30 caracteres");
          }
          if (
            this.descarga.entidad_muestradora != undefined &&
            this.descarga.entidad_muestradora.length > 30
          ) {
            this.errors.push("Entidad muestradora no debe superar 30 caracteres");
          }
          if (
            this.descarga.mortalidad != undefined &&
            this.descarga.mortalidad.length > 30
          ) {
            this.errors.push("Mortalidad no debe superar 30 caracteres");
          }
        }
        if (this.errors.length > 0) {
          //this.validation_message = this.errors.join(". ");
          this.validation_message = "Revise los siguientes problemas de validación:";
          this.showSnackBar(true);
          return;
        }
        let jaulas = [];
        this.descarga.descargas_jaulas.forEach((l) => {
          let jaula = {
            descargas_id: l.descargas_id,
            bodegas_id: l.bodegas_id,
            hora_inicio: this.moment(l.hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
            hora_termino: this.moment(l.hora_termino).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            jaula: l.jaula,
            smolts: l.smolts,
          };
          jaulas.push(jaula);
        });
  
        let tecnico_wellboat = this.descarga.tecnico;
        if(tecnico_wellboat){
            if (typeof tecnico_wellboat === "object") {
                tecnico_wellboat = this.descarga.tecnico.nombre;
            }
        }
  
        let capitan = "";
        if(this.descarga.capitan){
            if (typeof this.descarga.capitan === "object") {
            capitan = this.descarga.capitan.nombre;
            } else {
            capitan = this.descarga.capitan;
            }
        }
  
        let url = `${this.base_url}descargas`;
        let descargaData = {
          main: {
            partes_id: this.descarga.parte.id,
            wellboats_id: this.user.wellboat.id,
            muestreo_repla: this.descarga.muestreo_repla,
            entidad_muestradora: this.descarga.entidad_muestradora,
            mortalidad: this.descarga.mortalidad,
            saturacion_centro: this.descarga.saturacion_centro,
            temperatura_centro: this.descarga.temperatura_centro,
            oxigeno_centro: this.descarga.oxigeno_centro,
            observaciones: this.descarga.observaciones,
            capitan: capitan,
            tecnico: tecnico_wellboat,
            encargado_acopio: this.descarga.encargado_acopio,
            temporal: temporal,
          },
          jaulas: jaulas,
        };
        let response = null;
        if (this.descarga.id) {
          url = url + "/" + this.descarga.id;
          try {
            response = await this.axios.put(url, descargaData, this.headers_json);
            //this.onClickTicket(this.descarga.parte);
            this.onClickTicket(this.parte);
            this.showSnackBar(false);
          } catch (error) {
            response = error.response;
            this.errors = response.data.data
            /*let msg = response.data.data;
            this.validation_message =
              response.status + ": " + response.data.message + ". " + msg;*/
            this.validation_message = "Revise los siguientes problemas de validación:";
            this.showSnackBar(true);
          }
        } else {
          try {
            response = await this.axios.post(
              url,
              descargaData,
              this.headers_json
            );
            //this.onClickTicket(this.descarga.parte);
            this.onClickTicket(this.parte);
            this.showSnackBar(false);
          } catch (error) {
            response = error.response;
            this.errors = response.data.data
            /*let msg = response.data.data;
            this.validation_message =
              response.status + ": " + response.data.message + ". " + msg;*/
            this.validation_message = "Revise los siguientes problemas de validación:";
            this.showSnackBar(true);
          }
        }
        if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
          window.open(this.storage_url + response.data.url_pdf);
        }
      },
      async saveFinal() {
        if (this.files.length < 1) {
          this.errors.push("Debe subir al menos 1 documento");
        }
        if (this.errors.length > 0) {
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let formData = new FormData();
        let nf = this.files.length;
        let i = 0;
        this.files.forEach((f) => {
          formData.append("doc" + i, f);
          i++;
        });
        formData.append("partes_id", this.carga.parte.id);
        formData.append("num_files", nf);
  
        let response = null;
        let url = `${this.base_url}cargas/bitacoras/finalizar`;
        this.headers.headers["Content-Type"] = "multipart/form-data";
        try {
          response = await this.axios.post(url, formData, this.headers_file);
          /*this.showSnackBar(
            false,
            "Operación exitosa. Ahora debe ir a Parte de viaje, ingresar Fecha/Hora CAM y Guardar Parte de Viaje"
          );*/
          this.$alert("¡Ejecutado exitósamente! debe ir al parte de viaje, indicar fecha/hora CAM y guardar el parte de viaje");
        } catch (error) {
          response = error.response;
          let msg = response.data.data;
          this.validation_message =
            response.status + ": " + response.data.message + ". " + msg;
          this.showSnackBar(true);
        }
      },
      addLance() {
        let n = this.carga.lances.length;
        let lance = {
          id: 0,
          menu1: false,
          menu2: false,
          menu3: false,
          cargas_id: 0,
          numero: n + 1,
          hora_inicio: "00:00",
          //'hora_medicion': '00:00',
          hora_medicion: null,
          oxigeno: "0",
          saturacion: "0",
          temperatura: "0",
          peces: "0",
          hora_termino: "00:00",
          carga_efectiva: "00:00",
        };
        console.log(lance);
        this.$set(this.carga.lances, n, lance);
      },
      addMedicionTraslado() {
        let n = this.traslado.parametros.length;
        let parametro = {
          id: 0,
          menu1: false,
          traslados_id: 0,
          numero: n + 1,
        };
        this.bodegas.forEach((b) => {
          // parametro["b" + b.bodega.id] = {
          //   oxigeno: 0,
          //   ph: 0,
          //   uv: 0,
          //   temperatura: 0,
          // };
          parametro['b' + b.bodega.id] = { oxigeno: null, ph: null, uv: null, temperatura: null }
        });
        if (n == 0) {
          this.traslado.parametros = [];
          this.traslado.parametros.push(parametro);
        } else {
          this.$set(this.traslado.parametros, n, parametro);
        }
        this.$set(this.traslado.parametros, n, parametro);
      },
      addCargaBodega() {
        console.log("addCargaBodega");
        this.errors = [];
        if (!this.bodega.bodega) {
          this.errors.push("Debe elegir una bodega");
        }
        if (!this.bodega.peces > 0) {
          this.errors.push("Debe indicar peces");
        }
        if (!this.bodega.peso > 0) {
          this.errors.push("Debe indicar peso");
        }
        if (!this.bodega.biomasa > 0) {
          this.errors.push("Debe indicar biomasa");
        }
        if (!this.bodega.densidad > 0) {
          this.errors.push("Debe indicar densidad");
        }
        if (isNaN(this.bodega.estanques)) {
          this.errors.push("Debe indicar estanques");
        }
        if (this.errors.length > 0) {
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let n = this.carga.bodegas.length;
        let b = {
          id: 0,
          numero: n + 1,
          bodega: this.bodega.bodega,
          peces: this.bodega.peces,
          peso: this.bodega.peso,
          biomasa: Math.round(this.bodega.biomasa, 0),
          densidad: this.bodega.densidad,
          lances: 0,
          jaula: "",
          estanques: this.bodega.estanques,
          hora_inicio: this.bodega.hora_inicio,
          hora_termino: this.bodega.hora_termino,
        };
        console.log(b);
        this.carga.bodegas.push(b);
        this.dialog = false;
        this.bodega = {
          bodega: {},
          peces: 0,
          peso: 0,
          biomasa: 0,
          densidad: 0,
          lances: "",
          jaula: "",
        };
      },
      addDescarga() {
        let n = this.descarga.descargas_jaulas.length;
        console.log(n);
        let descarga = {
          id: 0,
          menu1: false,
          menu2: false,
          descargas_id: 0,
          bodega: this.bodegas[0],
          numero: n + 1,
          /*hora_inicio: "00:00",
        hora_termino: "00:00",*/
          hora_inicio:null,
          hora_termino:null,
          jaula: "",
        };
        this.$set(this.descarga.descargas_jaulas, n, descarga);
      },
      deleteBodega(item,index) {
        //const index = this.carga.bodegas.indexOf(item);
        this.carga.bodegas.splice(index, 1);
      },
      deleteLance(item) {
        const index = this.carga.lances.indexOf(item);
        this.carga.lances.splice(index, 1);
      },
      deleteDescargaJaula(item,index) {
        //const index = this.descarga.descargas_jaulas.indexOf(item);
        this.descarga.descargas_jaulas.splice(index, 1);
      },
      deleteParametroTraslado(item,index) {
        //const index = this.traslado.parametros.indexOf(item);
        this.traslado.parametros.splice(index, 1);
      },
      showSnackBar(error, mensaje = "Ejecutado exitosamente!") {
        if (mensaje == null) mensaje = "Ejecutado exitosamente!";
        if (error) {
          this.color = "red";
        } else {
          this.color = "green";
          this.validation_message = mensaje;
          this.cleanForm();
        }
        this.snackbar = true;
      },
      cleanForm() {
        return;
      },
      ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      },
      reformatDateTime(datetime) {
        if (!datetime) return null;
        const [date, time] = datetime.split(" ");
        const [day, month, year] = date.split("-");
        return `${year}-${month}-${day} ${time}`;
      },
      calcularBiomasa() {
        let peso = this.bodega.peso.toString();
        peso = peso.replace(",", ".");
        this.bodega.peso = peso;
        console.log(this.bodega.bodega.m3);
        this.bodega.biomasa = (
          parseFloat(this.bodega.peces) * parseFloat(peso)
        ).toFixed(0);
        this.bodega.densidad = (
          this.bodega.biomasa / this.bodega.bodega.m3
        ).toFixed(2);
      },
      checkGuia() {
        if (/^[0-9-]+$/.test(this.carga.guia_despacho) == false) {
          this.validation_message = "Formato no válido en guía de despacho";
          this.showSnackBar(true);
        }
      },
      cargaEfectiva(lance) {
        if (lance.hora_inicio != "00:00" && lance.hora_termino != "00:00") {
          let h2 = this.moment(lance.hora_termino);
          let h1 = this.moment(lance.hora_inicio);
          let seconds = h2.diff(h1, "seconds");
          let millis = this.moment
            .duration({ seconds: seconds })
            .asMilliseconds();
          let tiempo = this.moment.utc(millis).format("HH:mm");
          const index = this.carga.lances.indexOf(lance);
          lance.carga_efectiva = tiempo;
          this.$set(this.carga.lances, index, lance);
        }
      },
      recalcular(item) {
        let index = this.carga.bodegas.indexOf(item);
        let bodega = item;
        let bod = this.bodegas.find(b => b.bodega.id == item.bodega.id);
        let biomasa = Math.round(bodega.peces * bodega.peso);
        bodega.biomasa = biomasa;
        bodega.densidad = (biomasa / bod.m3).toFixed(2);
        //console.log('bodega',bod)
        //console.log('bodegas',this.bodegas)
        this.$set(this.carga.bodegas, index, bodega);
      },
      checkLanceVsBodega(peces) {
        // validar total lances vs total bodegas
        let sum_bodegas = parseInt(peces);
        let sum_lances = 0;
        this.carga.lances.forEach((l) => {
          sum_lances += parseInt(l.peces);
        });
        this.carga.bodegas.forEach((b) => {
          sum_bodegas += parseInt(b.peces);
        });
        return sum_lances == sum_bodegas;
      },
    },
    computed: {
      ...mapState([
        "storage_url",
        "loading",
        "base_url",
        "headers",
        "headers_json",
        "headers_file",
        "user",
      ]),
      fechaGuiaFormatted() {
        return this.formatDate(this.carga.fecha_despacho);
      },
      fechaGuiaTraslado() {
        return this.formatDate(this.traslado.fecha_despacho);
      },
      totalPeces() {
        let total = 0;
        this.carga.bodegas.forEach((b) => {
          total += parseFloat(b.peces);
        });
        return total;
      },
      totalPeso() {
        if (this.totalBiomasa != 0 && this.totalPeces != 0) {
          return (this.totalBiomasa / this.totalPeces).toFixed(3);
        }
        return 0;
      },
      totalBiomasa() {
        let total = 0;
        this.carga.bodegas.forEach((b) => {
          total += parseFloat(b.biomasa);
        });
        return total;
      },
      totalDensidad() {
        let total = 0;
        this.carga.bodegas.forEach((b) => {
          total += parseFloat(b.biomasa);
        });
        let densidad = this.user.wellboat.m3;
        return (total / densidad).toFixed(2);
      },
      horaInicio() {
        // el siguiente parte de viaje, interno o de cosecha
        return this.moment(this.carga.parte.zarpe).format("DD-MM-YYYY HH:mm");
      },
      horaTermino() {
        return this.moment(this.carga.parte.recalada).format("DD-MM-YYYY HH:mm");
      },
      centroCarga() {
        if (this.carga.parte.sentidos_id == 1) {
          if (this.carga.parte.origen != undefined) {
            return this.carga.parte.origen.nombre;
          }
        }
        if (this.carga.parte.sentidos_id == 2) {
          if (this.carga.parte.destino != undefined) {
            return this.carga.parte.destino.nombre;
          }
        }
        return "";
      },
      centroDescarga() {
        if (this.carga.parte.sentidos_id == 1) {
          if (this.carga.parte.destino != undefined) {
            return this.carga.parte.destino.nombre;
          }
        }
        if (this.carga.parte.sentidos_id == 2) {
          if (this.carga.parte.origen != undefined) {
            return this.carga.parte.origen.nombre;
          }
        }
        return "";
      },
      finalizar() {
        // si es la primera carga de un parte a duplicar
        if (this.parte.duplicar == 1 && this.carga.temporal == 0) {
          return false;
        }
        if (
          this.carga.temporal == 0 &&
          this.traslado.temporal == 0 &&
          this.descarga.temporal == 0
        ) {
          return true;
        }
        return false;
      },
      showCarga() {
        return Object.keys(this.parte).length === 0;
      },
    },
    watch: {
      totalDensidad: function (act, prev) {
        if (act != prev && act > this.user.wellboat.toneladas) {
          this.validation_message = `Se sobrepasa densidad wellboat (${this.user.wellboat.toneladas})`;
          this.showSnackBar(true);
        }
      },
    },
    components: {
      TicketBitacora,
    },
  };
  </script>
  